import { default as aboutKgHt9qCcRWMeta } from "/workspace/run/nuxt-service/pages/about.vue?macro=true";
import { default as _91slug_93aiVcUu3FfVMeta } from "/workspace/run/nuxt-service/pages/analysis/website/[slug].vue?macro=true";
import { default as remove2T3zA6LNwLMeta } from "/workspace/run/nuxt-service/pages/analysis/website/remove.vue?macro=true";
import { default as lighthouse0LqGR9VtkHMeta } from "/workspace/run/nuxt-service/pages/analysis/website/reports/lighthouse.vue?macro=true";
import { default as contactudej3d4c2wMeta } from "/workspace/run/nuxt-service/pages/contact.vue?macro=true";
import { default as _91slug_93VPSLdLA28cMeta } from "/workspace/run/nuxt-service/pages/content/articles/[slug].vue?macro=true";
import { default as indexydUaP3oKmmMeta } from "/workspace/run/nuxt-service/pages/content/articles/index.vue?macro=true";
import { default as faqoFG2poIUaPMeta } from "/workspace/run/nuxt-service/pages/faq.vue?macro=true";
import { default as imprintGa9i6rey5BMeta } from "/workspace/run/nuxt-service/pages/imprint.vue?macro=true";
import { default as index8exmaUqUtPMeta } from "/workspace/run/nuxt-service/pages/index.vue?macro=true";
import { default as awesomedO6jhcb3YiMeta } from "/workspace/run/nuxt-service/pages/insights/technologies/awesome.vue?macro=true";
import { default as most_45usedqCRzNy1I2CMeta } from "/workspace/run/nuxt-service/pages/insights/technologies/most-used.vue?macro=true";
import { default as awesome5FiGQNcCKTMeta } from "/workspace/run/nuxt-service/pages/insights/websites/awesome.vue?macro=true";
import { default as recently_45analyzedvBIMxERIlYMeta } from "/workspace/run/nuxt-service/pages/insights/websites/recently-analyzed.vue?macro=true";
import { default as top_45tech_45stackzpcwyT17I9Meta } from "/workspace/run/nuxt-service/pages/insights/websites/top-tech-stack.vue?macro=true";
import { default as pricingHA501CoyfJMeta } from "/workspace/run/nuxt-service/pages/pricing.vue?macro=true";
import { default as privacySWRgK8Y3KNMeta } from "/workspace/run/nuxt-service/pages/privacy.vue?macro=true";
import { default as docsoQnSSvQgzzMeta } from "/workspace/run/nuxt-service/pages/products/website-analyzer/docs.vue?macro=true";
import { default as indexisa4ieKe9nMeta } from "/workspace/run/nuxt-service/pages/products/website-analyzer/index.vue?macro=true";
import { default as _91slug_93xMkc43g9UsMeta } from "/workspace/run/nuxt-service/pages/technologies/[category]/[slug].vue?macro=true";
import { default as _91slug_93FEI0YcvcXFMeta } from "/workspace/run/nuxt-service/pages/technologies/[slug].vue?macro=true";
import { default as indexnjkGcHlOJBMeta } from "/workspace/run/nuxt-service/pages/technologies/index.vue?macro=true";
import { default as terms0Uph9cJhKBMeta } from "/workspace/run/nuxt-service/pages/terms.vue?macro=true";
import { default as accountTN8c6PVLg1Meta } from "/workspace/run/nuxt-service/pages/user/account.vue?macro=true";
import { default as api_45keysZwcWNRaeBsMeta } from "/workspace/run/nuxt-service/pages/user/api-keys.vue?macro=true";
import { default as deletedrmgRpIr1lvMeta } from "/workspace/run/nuxt-service/pages/user/deleted.vue?macro=true";
import { default as historyQYoJM297kFMeta } from "/workspace/run/nuxt-service/pages/user/history.vue?macro=true";
import { default as invoiceNnagtf4pIWMeta } from "/workspace/run/nuxt-service/pages/user/invoice.vue?macro=true";
import { default as payment_45methodstalmV9fzmMMeta } from "/workspace/run/nuxt-service/pages/user/payment-methods.vue?macro=true";
import { default as stories3kxlcbzNlUMeta } from "/workspace/run/nuxt-service/pages/user/stories.vue?macro=true";
import { default as subscription15PDp5ct3NMeta } from "/workspace/run/nuxt-service/pages/user/subscription.vue?macro=true";
import { default as webhook_45endpointszf7NxO14XIMeta } from "/workspace/run/nuxt-service/pages/user/webhook-endpoints.vue?macro=true";
import { default as whyIsr1Z6FHpMMeta } from "/workspace/run/nuxt-service/pages/why.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/workspace/run/nuxt-service/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "analysis-website-slug",
    path: "/analysis/website/:slug()",
    component: () => import("/workspace/run/nuxt-service/pages/analysis/website/[slug].vue").then(m => m.default || m)
  },
  {
    name: "analysis-website-remove",
    path: "/analysis/website/remove",
    component: () => import("/workspace/run/nuxt-service/pages/analysis/website/remove.vue").then(m => m.default || m)
  },
  {
    name: "analysis-website-reports-lighthouse",
    path: "/analysis/website/reports/lighthouse",
    component: () => import("/workspace/run/nuxt-service/pages/analysis/website/reports/lighthouse.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/workspace/run/nuxt-service/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "content-articles-slug",
    path: "/content/articles/:slug()",
    component: () => import("/workspace/run/nuxt-service/pages/content/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: "content-articles",
    path: "/content/articles",
    component: () => import("/workspace/run/nuxt-service/pages/content/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/workspace/run/nuxt-service/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "imprint",
    path: "/imprint",
    component: () => import("/workspace/run/nuxt-service/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/run/nuxt-service/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "insights-technologies-awesome",
    path: "/insights/technologies/awesome",
    component: () => import("/workspace/run/nuxt-service/pages/insights/technologies/awesome.vue").then(m => m.default || m)
  },
  {
    name: "insights-technologies-most-used",
    path: "/insights/technologies/most-used",
    component: () => import("/workspace/run/nuxt-service/pages/insights/technologies/most-used.vue").then(m => m.default || m)
  },
  {
    name: "insights-websites-awesome",
    path: "/insights/websites/awesome",
    component: () => import("/workspace/run/nuxt-service/pages/insights/websites/awesome.vue").then(m => m.default || m)
  },
  {
    name: "insights-websites-recently-analyzed",
    path: "/insights/websites/recently-analyzed",
    component: () => import("/workspace/run/nuxt-service/pages/insights/websites/recently-analyzed.vue").then(m => m.default || m)
  },
  {
    name: "insights-websites-top-tech-stack",
    path: "/insights/websites/top-tech-stack",
    component: () => import("/workspace/run/nuxt-service/pages/insights/websites/top-tech-stack.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/workspace/run/nuxt-service/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/workspace/run/nuxt-service/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "products-website-analyzer-docs",
    path: "/products/website-analyzer/docs",
    component: () => import("/workspace/run/nuxt-service/pages/products/website-analyzer/docs.vue").then(m => m.default || m)
  },
  {
    name: "products-website-analyzer",
    path: "/products/website-analyzer",
    component: () => import("/workspace/run/nuxt-service/pages/products/website-analyzer/index.vue").then(m => m.default || m)
  },
  {
    name: "technologies-category-slug",
    path: "/technologies/:category()/:slug()",
    component: () => import("/workspace/run/nuxt-service/pages/technologies/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "technologies-slug",
    path: "/technologies/:slug()",
    component: () => import("/workspace/run/nuxt-service/pages/technologies/[slug].vue").then(m => m.default || m)
  },
  {
    name: "technologies",
    path: "/technologies",
    component: () => import("/workspace/run/nuxt-service/pages/technologies/index.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/workspace/run/nuxt-service/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "user-account",
    path: "/user/account",
    component: () => import("/workspace/run/nuxt-service/pages/user/account.vue").then(m => m.default || m)
  },
  {
    name: "user-api-keys",
    path: "/user/api-keys",
    component: () => import("/workspace/run/nuxt-service/pages/user/api-keys.vue").then(m => m.default || m)
  },
  {
    name: "user-deleted",
    path: "/user/deleted",
    component: () => import("/workspace/run/nuxt-service/pages/user/deleted.vue").then(m => m.default || m)
  },
  {
    name: "user-history",
    path: "/user/history",
    component: () => import("/workspace/run/nuxt-service/pages/user/history.vue").then(m => m.default || m)
  },
  {
    name: "user-invoice",
    path: "/user/invoice",
    component: () => import("/workspace/run/nuxt-service/pages/user/invoice.vue").then(m => m.default || m)
  },
  {
    name: "user-payment-methods",
    path: "/user/payment-methods",
    component: () => import("/workspace/run/nuxt-service/pages/user/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: "user-stories",
    path: "/user/stories",
    component: () => import("/workspace/run/nuxt-service/pages/user/stories.vue").then(m => m.default || m)
  },
  {
    name: "user-subscription",
    path: "/user/subscription",
    component: () => import("/workspace/run/nuxt-service/pages/user/subscription.vue").then(m => m.default || m)
  },
  {
    name: "user-webhook-endpoints",
    path: "/user/webhook-endpoints",
    component: () => import("/workspace/run/nuxt-service/pages/user/webhook-endpoints.vue").then(m => m.default || m)
  },
  {
    name: "why",
    path: "/why",
    component: () => import("/workspace/run/nuxt-service/pages/why.vue").then(m => m.default || m)
  }
]